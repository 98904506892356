import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, PinInput, PinInputField, Spacer, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React from 'react'
import { toastFunctionToaster } from '../../../utils/toastFunction'
import { useEffect } from 'react'
import { useState } from 'react'
import { controlSpaceContentList, verifySpaceAccess } from '../../../services/sharedServices'
import { useTextColor } from '../../../theme/globalColorTheme'
import { useRef } from 'react'
import ControlSpaceAccessContents from '../components/ControlSpaceAccessContents'
import DisplayContent from '../components/DisplayContent'
import jwtDecode from "jwt-decode";
import Sidebar from './SideBar'
import Card from '../../../components/card/Card'


const VerifyControlAccess = ({ space_name, setUserEmail }) => {
    const [loading, setLoading] = useState(false)
    const [accessCode, setAccessCode] = useState(null)
    const [valueOne, setValueOne] = useState("")
    const [valueTwo, setValueTwo] = useState("")
    const [valueThree, setValueThree] = useState("")
    const [valueFour, setValueFour] = useState("")
    const [valueFive, setValueFive] = useState("")
    const [valueSix, setValueSix] = useState("")
    const [jwtToken, setJwtToken] = useState(null)
    const [pageId, setPageId] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [Listloading, setListLoading] = useState(false)
    const [data, setData] = useState([])
    const [fileDetails, setFileDetails] = useState(null)

    const firstPinRef = useRef(null);
    const toast = useToast()
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure()
    const [isCodeValid, setIsCodeValid] = useState(false);
    const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100")
    const [projectUuid, setProjectUuid] = useState(null)

    function handleSendOrVerifyAccessCode() {
        setLoading(true)
        const paramObj = {
            id: space_name,
        }
        if (accessCode) {
            paramObj["access_code"] = accessCode
        }
        verifySpaceAccess(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                if (resp?.data[0]?.email_sent) {
                    toast(toastFunctionToaster(resp?.message, "success"))
                } else if (resp?.data[0]?.access_verified) {
                    toast(toastFunctionToaster(resp?.message, "success"))
                    setJwtToken(resp?.data[0]?.token)
                    const decodedData = jwtDecode(resp?.data[0]?.token)
                    setUserEmail(decodedData?.user_email)
                    setProjectUuid(decodedData?.project_uuid)
                    handleClearPindata()
                }
            }
            else {
                toast(toastFunctionToaster(resp?.message || resp?.error, "error"))
                setJwtToken(null)
                setUserEmail(null)
                setProjectUuid(null)
                handleClearPindata()
            }
        }).catch(e => {
            setJwtToken(null)
            setUserEmail(null)
            setProjectUuid(null)
            handleClearPindata()
            toast(toastFunctionToaster("Failed to verify", "error"))
        })
            .finally(() => setLoading(false))
    }

    function handleGetContentList() {
        if (!jwtToken) return
        setListLoading(true)
        const paramObj = {
            token: jwtToken,
            page_id: pageId,
            records_per_page: recordsPerPage
        }
        controlSpaceContentList(paramObj).then(resp => {
            if (resp?.data[0]?.data?.data) {
                setData(resp?.data[0]?.data?.data)
                setTotalPages(resp?.data[0]?.data?.total_pages)
            } else {
                setData([])
                setTotalPages(0)
            }
        }).catch(err => {
            setData([])
            setTotalPages(0)
        }).finally(() => {
            setListLoading(false)
        })
    }


    function handleClearPindata() {
        setAccessCode(null)
        setValueOne(null)
        setValueTwo(null)
        setValueThree(null)
        setValueFour(null)
        setValueFive(null)
        setValueSix(null)
    }

    function handleOpenFile(file) {
        setFileDetails(file)
        onDrawerOpen()
    }

    function handleDrawerClose() {
        onDrawerClose()
        setFileDetails(null)
    }

    function handleKeyDown(e){
        if(e.key === "Enter" && isCodeValid && !loading){
            handleSendOrVerifyAccessCode()
        }
    }

    useEffect(() => {
        if (!space_name) {
            toast(toastFunctionToaster("Invalid Space Name", "error"))
        }
        space_name && handleSendOrVerifyAccessCode()
        firstPinRef?.current?.focus()
    }, [space_name])

    useEffect(() => {
        const code = `${valueOne}${valueTwo}${valueThree}${valueFour}${valueFive}${valueSix}`;
        const flag = code && code.length === 6
        setIsCodeValid(flag)
        flag && setAccessCode(code)
    }, [valueOne, valueTwo, valueThree, valueFour, valueFive, valueSix]);

    useEffect(() => {
        if (jwtToken) {
            handleGetContentList()
        }
    }, [jwtToken])

    useEffect(() => {
        handleGetContentList()
    }, [pageId])

    useEffect(() => {
        setPageId(1)
        handleGetContentList()
    }, [recordsPerPage])

    return (
        <Flex w="full" h="full">
            {jwtToken ?
                <Flex p="4" h="full" pb={8} pt="0" w="full">
                    <ControlSpaceAccessContents data={data} pageId={pageId} recordsPerPage={recordsPerPage} setPageId={setPageId} setRecordsPerPage={setRecordsPerPage} totalPages={totalPages} loading={Listloading} handleModalOpen={handleOpenFile} />
                    <Drawer isOpen={isDrawerOpen} placement="right" onClose={handleDrawerClose} size="lg">
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerHeader>View Content</DrawerHeader>
                            <DrawerCloseButton color={"red.100"} />
                            <DrawerBody overflow={"hidden"}>
                                <DisplayContent projectUuid={projectUuid} pageContent={fileDetails} />
                            </DrawerBody>
                            <DrawerFooter>
                                <Button variant="outline" mr={3} onClick={handleDrawerClose}>
                                    Cancel
                                </Button>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </Flex>
                : <>
                    {
                        space_name ?
                            <Flex h="full" w="full" justifyContent="center" pt="20">
                                <Card borderWidth="0.5px" borderColor="green.100" h="fit-content" w="fit-content" pt="1.5" pb="1.5">
                                    <Flex flexDir="column" gap={4}>
                                        <Heading fontSize={"20px"} fontWeight={600} my="2">
                                            Please input file access code (Sent your email):
                                        </Heading>
                                        <Flex
                                            zIndex="2"
                                            direction="column"
                                            w={{ base: "100%", md: "425px" }}
                                            maxW="100%"
                                            background="transparent"
                                            borderRadius="15px"
                                            mx={{ base: "auto", lg: "unset" }}
                                            me="auto"
                                            mb={{ base: "20px", md: "auto" }}
                                        >
                                            <FormControl>
                                                <Flex justify="center">
                                                    <PinInput mx="auto" otp>
                                                        <PinInputField
                                                            fontSize="30px"
                                                            ref={firstPinRef}
                                                            color={useTextColor}
                                                            borderRadius="16px"
                                                            borderColor={borderColor}
                                                            type='number'
                                                            min={1}
                                                            max={9}
                                                            h={{ base: "55px", md: "80px" }}
                                                            w={{ base: "63px", md: "95px" }}
                                                            me="10px"
                                                            value={valueOne}
                                                            onChange={e => {
                                                                (e.target.value >= 1 && e.target.value <= 9) && setValueOne(e.target.value)
                                                            }}
                                                        />
                                                        <PinInputField
                                                            fontSize="30px"
                                                            color={useTextColor}
                                                            borderRadius="16px"
                                                            borderColor={borderColor}
                                                            h={{ base: "55px", md: "80px" }}
                                                            w={{ base: "63px", md: "95px" }}
                                                            type='number'
                                                            min={0}
                                                            max={9}
                                                            me="10px"
                                                            value={valueTwo}
                                                            onChange={e => {
                                                                (e.target.value >= 0 && e.target.value <= 9) && setValueTwo(e.target.value)
                                                            }}
                                                        />
                                                        <PinInputField
                                                            fontSize="30px"
                                                            color={useTextColor}
                                                            borderRadius="16px"
                                                            borderColor={borderColor}
                                                            h={{ base: "55px", md: "80px" }}
                                                            w={{ base: "63px", md: "95px" }}
                                                            type='number'
                                                            min={0}
                                                            max={9}
                                                            me="10px"
                                                            value={valueThree}
                                                            onChange={e => {
                                                                (e.target.value >= 0 && e.target.value <= 9) && setValueThree(
                                                                    e.target.value
                                                                )
                                                            }}
                                                        />
                                                        <PinInputField
                                                            fontSize="30px"
                                                            color={useTextColor}
                                                            borderRadius="16px"
                                                            borderColor={borderColor}
                                                            type='number'
                                                            min={0}
                                                            max={9}
                                                            h={{ base: "55px", md: "80px" }}
                                                            w={{ base: "63px", md: "95px" }}
                                                            value={valueFour}
                                                            onChange={e => {
                                                                (e.target.value >= 0 && e.target.value <= 9) && setValueFour(e.target.value)
                                                            }}
                                                        />
                                                        <PinInputField
                                                            ml={2}
                                                            fontSize="30px"
                                                            color={useTextColor}
                                                            borderRadius="16px"
                                                            borderColor={borderColor}
                                                            type='number'
                                                            min={0}
                                                            max={9}
                                                            h={{ base: "55px", md: "80px" }}
                                                            w={{ base: "63px", md: "95px" }}
                                                            value={valueFive}
                                                            onChange={e => {
                                                                (e.target.value >= 0 && e.target.value <= 9) && setValueFive(e.target.value)
                                                            }}
                                                        />
                                                        <PinInputField
                                                            ml={2}
                                                            fontSize="30px"
                                                            color={useTextColor}
                                                            borderRadius="16px"
                                                            borderColor={borderColor}
                                                            type='number'
                                                            min={0}
                                                            max={9}
                                                            h={{ base: "55px", md: "80px" }}
                                                            w={{ base: "63px", md: "95px" }}
                                                            value={valueSix}
                                                            onChange={e => {
                                                                (e.target.value >= 0 && e.target.value <= 9) && setValueSix(e.target.value)
                                                            }}
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </PinInput>
                                                </Flex>
                                            </FormControl>
                                        </Flex>
                                        <Flex>
                                            <Spacer />
                                            <Button
                                                // isDisabled={isCodeValid ? false : true}
                                                isLoading={loading ? true : false}
                                                onClick={handleSendOrVerifyAccessCode}
                                                colorScheme={isCodeValid ? "green" : "red"}
                                                mr={3}
                                            >
                                                Verify
                                            </Button>
                                            
                                        </Flex>
                                    </Flex>
                                </Card>
                            </Flex>
                            : <Flex justifyContent="center"><Text fontSize="xl" p="2">Invalid Space Name</Text></Flex>
                    }
                </>
            }
        </Flex>

    )
}

export default VerifyControlAccess